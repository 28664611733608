<script>
// import InstitutionIntroduceVue from '@/components/introduce/Labs/InstitutionIntroduce.vue'
// import HistoryVue from '@/components/introduce/Labs/History.vue'
import LabsAndCompanyVue from '@/components/introduce/Labs/LabsAndCompany.vue'

export default {
  components: {
    // InstitutionIntroduceVue,
    // HistoryVue,
    LabsAndCompanyVue
  }
}
</script>

<template>
  <div class="wf__lab-intro">
    <!-- <InstitutionIntroduceVue></InstitutionIntroduceVue>
    <HistoryVue></HistoryVue> -->
    <LabsAndCompanyVue></LabsAndCompanyVue>
  </div>
</template>

<style scoped>
.wf__lab-intro {
  width: 100%;
  max-width: var(--wf-container-width);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
