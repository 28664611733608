<script>
// import LaCSvg from './LaCSvg.vue'
import IntroduceImgUrl from '@/assets/images/introduce-locals.jpg'
import Logos from '@/assets/images/LabLogos'
export default {
  components: {
    // LaCSvg
  },
  data() {
    return {
      Logos,
      IntroduceImgUrl
    }
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<template>
  <div class="wf__lac">
    <div class="wf__intro-desc-item" :style="{ 'grid-column': '1/3' }">
      <div class="wf__intro-desc-title">유망연구실 참여기관</div>
      <div class="wf__lac-department-desc-logos">
        <img v-for="(logo, i) in Logos" :key="i" :src="logo" alt="" />
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wf__lac {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 22px;
  padding: 100px 2em;
}

.wf__lac-intro-title {
  grid-column: 1 / 3;
}

.wf__lac-desc {
  display: flex;
  flex-direction: column;
  gap: 57px;
  margin-top: 34px;
}

.wf__lac-department-desc {
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 15px;
}
.wf__lac-department-desc-item {
  list-style: disc;
  margin-left: 1em;
}
.wf__lac-department-desc-item::marker {
  color: var(--wf-primary-color);
  font-size: 30px;
}
.wf__lac-department-desc-item-title {
  font-size: 24px;
  font-weight: 500;
}
.wf__lac-department-desc-item-desc {
  font-size: 20px;
  font-weight: 300;
}

.wf__lac-department-desc-logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-items: center;

  margin-top: 30px;

  column-gap: 40px;
  row-gap: 30px;
}

.wf__lac-department-desc-logos img {
  width: 240px;
  height: 100px;
  object-fit: contain;
  object-position: center;
}

.wf__lac-pic:deep(svg) {
  width: 100%;
  height: 100%;

  min-width: 784px;
  min-height: 914px;
}
.wf__lac-pic > img {
  width: 100%;
  height: 100%;

  max-width: 780px;
  max-height: 920px;
  object-fit: contain;
  object-position: center;
}

@media screen and (max-width: 1024px) {
  .wf__lac-intro,
  .wf__lac-pic {
    grid-column: 1/3;
  }

  .wf__intro-desc-item {
    margin-top: 3em;
  }

  .wf__lac-pic:deep(svg) {
    min-width: 0px;
    min-height: 0px;
  }
}
@media screen and (max-width: 992px) {
  .wf__lac-department-desc-logos {
    grid-template-columns: repeat(2, 1fr);
  }
  .wf__lac-pic > img {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .wf__lac-department-desc-logos {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
